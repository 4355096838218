<template>
  <div>
 <infoFormMessage
      v-if="typeof result.infoFormMessage != 'undefined'"
      :message="result.infoFormMessage"
      :result="result"
      :type="result.infoType"
    ></infoFormMessage>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-card class="" outlined>
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>{{ language.menu_myAccount }}</v-toolbar-title>
          </v-toolbar>
          <v-spacer></v-spacer>
          <v-form
            ref="myAccount"
            action="serve.php/?f=administration&f2=userSelfService&f3=myAccount"
            method="post"
            v-model="isValid"
            @submit.prevent
          >
            <v-list-item>
              <v-list-item-content>
                <div class="body-2">
                  <v-row>
                    <v-col>
                      <fieldsSingleRows
                        @field-value-changed="checkInput(...arguments)"
                        field="n_id"
                        :templateContent="result"
                        :valueLine="result.valueLine"
                      ></fieldsSingleRows> <fieldsSingleRows
                        @field-value-changed="checkInput(...arguments)"
                        field="n_id_key"
                        :templateContent="result"
                        :valueLine="result.valueLine"
                      ></fieldsSingleRows>
                      <fieldsSingleRows
                        @field-value-changed="checkInput(...arguments)"
                        field="c_firstname"
                        :templateContent="result"
                        :valueLine="result.valueLine"
                      ></fieldsSingleRows>
                      <fieldsSingleRows
                        @field-value-changed="checkInput(...arguments)"
                        field="c_lastname"
                        :templateContent="result"
                        :valueLine="result.valueLine"
                      ></fieldsSingleRows>
                      <fieldsSingleRows
                        @field-value-changed="checkInput(...arguments)"
                        field="c_email"
                        :templateContent="result"
                        :valueLine="result.valueLine"
                      ></fieldsSingleRows>
                      <fieldsSingleRows
                        @field-value-changed="checkInput(...arguments)"
                        field="c_country"
                        :templateContent="result"
                        :valueLine="result.valueLine"
                      ></fieldsSingleRows>
                      <fieldsSingleRows
                        @field-value-changed="checkInput(...arguments)"
                        class=""
                        field="c_phone"
                        :templateContent="result"
                        :valueLine="result.valueLine"
                      ></fieldsSingleRows>
                    </v-col>
                    <!--  <v-col class="pl-7 mt-3">
                  <avatar
                    :result="result.valueLine.avatar.url"
                    :edit="true"
                    :textName="fullName"
                    fSize="40"
                  ></avatar>
                </v-col>-->
                  </v-row>

                  <!--<v-row>
                <v-col :cols="2" class="relativeTopN12"
                  >{{ language.myAccount.email }}:</v-col
                >
                <fieldsTds
                  :field="'c_email'"
                  :fieldAttributes="result.fields.c_email"
                  :templateContent="result"
                  :value="result.valueLine.c_email"
                ></fieldsTds>
              </v-row>-->
                  <!--  <v-row>
                <v-col :cols="2" class="relativeTopN12"
                  >{{ language.myAccount.role }}:</v-col
                >
                <fieldsTds
                  field="n_type"
                  :fieldAttributes="result.fields.n_type"
                  :templateContent="result"
                  :value="result.valueLine.n_type"
                ></fieldsTds>
              </v-row>-->
                  <v-row>
                    <v-col>
                      <primaryButton
                        :label="language.myAccount.changePass_title"
                        icon="mdi-lock-question"
                        type="button"
                        color="primary"
                        elevation="0"
                        target="_blank"
                        href="?f=administration&f2=userSelfService&f3=changePassword"
                        style=""
                        id="myAccount_changePass_butt"
                        :disabled="!isValid || result.disableMenu"
                      ></primaryButton>
                    </v-col>
                    <v-col>               
                      <addField
                        @loading-dialog="updateLoadingDialog1(...arguments)"
                        :path="'serve.php?f=administration&f2=userSelfService&f3=myAccount'"
                        :additionalAttributes="{
                          type: 'customDialog',
                          function: 'getRecoveryCode',
                          param: 1,
                          requiredPage:
                            'serve.php/?f=administration&f2=userSelfService&f3=myAccount',
                        }"
                        :dialogAttributes="{}"
                        :showDialog="openDialog1"
                        @dialog-closed="openDialog1 = false"
                  
                      >
                        <primaryButton
                          style=""
                          :label="language.myAccount.tfac_title"
                          icon="mdi-shield-lock"
                          type="button"
                          color="primary"
                          elevation="0"
                          :disabled="!isValid || loadingDialog1 || result.disableMenu"
                          :loading="loadingDialog1"
                        
                          :attributesArray="{
                            id: 'myAccount_configure2Fa_butt',
                          }"
                        ></primaryButton>
                      </addField>
                    </v-col>
                    <v-col v-if="showRecoveryList">
                      <addField
                        @loading-dialog="updateLoadingDialog2(...arguments)"
                        :path="'serve.php?f=administration&f2=userSelfService&f3=myAccount'"
                        :additionalAttributes="{
                          type: 'customDialog',
                          function: 'getRecoveryCode',
                          param: 0,
                          requiredPage:
                            'serve.php/?f=administration&f2=userSelfService&f3=myAccount',
                        }"
                        :dialogAttributes="{}"
                      >
                        <primaryButton
                          :label="language.myAccount.rc_title"
                          icon="mdi-view-list"
                          type="button"
                          color="primary"
                          elevation="0"
                          :disabled="loadingDialog2 || !isValid || result.disableMenu"
                          :loading="loadingDialog2"
                          @click="openDialog2 = true"
                          id="myAccount_recovery_butt"
                        ></primaryButton>
                      </addField>
                    </v-col>
                  </v-row>
                  <v-row v-if="showRecoveryList">
                    <v-col>
                      <v-dialog
                              v-model="dialog2faDisable"
                              max-width="350"
                      >
                        <v-card>
                          <v-card-title class="headline-box-headline">
                            {{language.componentLabel.labelResetTFA}}
                          </v-card-title>
                          <v-card-text>
                            {{language.componentLabel.labelClickYesDisableTFA}}
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="green darken-1"
                                    text
                                    @click="dialog2faDisable = false"
                            > {{language.componentLabel.labelNo}}
                            </v-btn>
                            <v-btn
                                    color="green darken-1"
                                    text
                                    @click="resetTFA(result.valueLine.n_id,result.valueLine.n_id_key)"
                            > {{language.componentLabel.labelYes}}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <primaryButton
                              label="Reset 2FA"
                              icon="mdi-lock-reset"
                              type="button"
                              color="primary"
                              elevation="0"
                              :disabled="!isValid || result.disableMenu"
                              @click.stop="dialog2faDisable = true"
                              id="myAccount_disable_2fa_butt"
                      ></primaryButton>
                    </v-col>
                  </v-row>
                </div>
              </v-list-item-content>
            </v-list-item>

            <!--
        <v-expansion-panels hover tile focusable>
          <v-expansion-panel>
            <v-expansion-panel-header id="myAccount_address_detail">{{
              language.myAccount.addressDetails
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <fieldsSingleRows
                @field-value-changed="checkInput(...arguments)"
                class="mt-4"
                field="c_phone"
                :templateContent="result"
                :valueLine="result.valueLine"
              ></fieldsSingleRows>
              <v-row no-gutters>
                <v-col cols="10" class="pr-2">
                  <fieldsValues
                    @input="checkInput('c_street1', ...arguments)"
                    field="c_street1"
                    :fieldAttributes="result.fields.c_street1"
                    :templateContent="result"
                    :value="result.valueLine.c_street1"
                  ></fieldsValues>
                </v-col>
                <v-col>
                  <fieldsValues
                    @input="checkInput('c_street2', ...arguments)"
                    field="c_street2"
                    :fieldAttributes="result.fields.c_street2"
                    :templateContent="result"
                    :value="result.valueLine.c_street2"
                  ></fieldsValues>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="3" class="pr-2">
                  <fieldsValues
                    @input="checkInput('c_zip', ...arguments)"
                    field="c_zip"
                    :fieldAttributes="result.fields.c_zip"
                    :templateContent="result"
                    :value="result.valueLine.c_zip"
                  ></fieldsValues>
                </v-col>
                <v-col>
                  <fieldsValues
                    @input="checkInput('c_city', ...arguments)"
                    field="c_city"
                    :fieldAttributes="result.fields.c_city"
                    :templateContent="result"
                    :value="result.valueLine.c_city"
                  ></fieldsValues>
                </v-col>
              </v-row>
              <fieldsSingleRows
                @field-value-changed="checkInput(...arguments)"
                field="c_country"
                :templateContent="result"
                :valueLine="result.valueLine"
              ></fieldsSingleRows>
              <fieldsSingleRows
                @field-value-changed="checkInput(...arguments)"
                class="mt-9"
                field="c_department"
                :templateContent="result"
                :valueLine="result.valueLine"
              ></fieldsSingleRows>
              <fieldsSingleRows
                @field-value-changed="checkInput(...arguments)"
                field="c_note"
                :templateContent="result"
                :valueLine="result.valueLine"
              ></fieldsSingleRows>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
-->

            <!--  <v-expansion-panels hover tile focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              language.myAccount.settings
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <myAccountPageSetup :result="result"  @field-value-changed="checkInput(...arguments)"></myAccountPageSetup>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>-->

            <v-card-actions class="max-auto">
              <fieldsButtons
                :result="result"
                @submitted="submitForm(...arguments)"
                :isValid="isValid"
              ></fieldsButtons>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-row>
    </v-container>
    <div class="pt-10"></div>
  </div>
</template>
<style scoped>
.relativeTopN12 {
  position: relative;
  top: -12px;
}
</style>
<script>
//import avatar from "@/components/avatar.vue";
import fieldsSingleRows from "@/commonComponents/fieldsSingleRows.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
//import fieldsTds from "@/commonComponents/fieldsTds.vue";
//import fieldsValues from "@/commonComponents/fieldsValues.vue";
import fieldsButtons from "@/commonComponents/fieldsButtons.vue";
//import myAccountPageSetup from "@/components/myAccountPageSetup.vue";
import addField from "@/commonComponents/addField.vue";
import infoFormMessage from "@/commonComponents/infoFormMessage.vue";
import requests from '@/js/requests';

export default {
  components: {
    infoFormMessage,
    //  avatar,
    primaryButton,
    fieldsSingleRows,
    // fieldsTds,
    fieldsButtons,
    // myAccountPageSetup,
    addField,
    //   fieldsValues,
  },
  props: ["result"],
  data: function () {
    return {
      dialog2faDisable:false,
      isValid: false,
      showRecoveryList: false,
      loadingDialog1: false,
      loadingDialog2: false,
      openDialog1: false,
      openDialog2: false,
      requiredPage: window.location,
/*      fullName:
        this.result.fields.c_firstname.value +
        " " +
        this.result.fields.c_lastname.value,*/
      // checkConfigure: !this.result.fields.c_2faSecret.value===true? "red":"green",
      // tfaTitle: !this.result.fields.c_2faSecret.value===true? "Not 2FA Configured":"2FA Configured",
    };
  },
  methods: {
      resetTFA(nid,key){
          //let caller = this;
          requests.frameworkAxiosRequest({
            method: 'POST',
            url: 'serve.php?f=administration&f2=userSelfService&f3=myAccount',
            dataType: 'json',
            data: {
              function: 'resetTFA',
              n_id: nid,
              n_id_key: key,
              requestType: 'ajax',
            },
          })
              .then(response => {
                  console.log(response);
                  this.showRecoveryList=false,
                 // caller.updateTable(response.data.result.table);
                  this.dialog2faDisable=false;
              })
              .catch(error => {
                  console.log(error);
              });
      },
    submitForm(functionName) {
      this.ajaxSubmit(
        functionName,
        this.$refs.myAccount.$el,
        this.result.tableUrl,
        this.result
      );
     
      this.$notify({
        group: "foo",
        text: "Successfully Updated!!",
        duration: 1000,
        type: "success",
        speed: 600,
      });
    },
    checkInput(field, value) {
      //this.$set(this.result.fields[field], "value", value);
      //this.$emit("dialog-result-changed", this.result);
      if (typeof this.result.valueLine == "undefined") {
        this.$set(this.result, "valueLine", {});
      }
      this.$set(this.result.valueLine, field, value);
      this.$emit("dialog-result-changed", this.result);
    },
    updateLoadingDialog1: function (loadingDialog1) {
      this.loadingDialog1 = loadingDialog1;
    },
    updateLoadingDialog2: function (loadingDialog2) {
      this.loadingDialog2 = loadingDialog2;
    },
  },
  created: function () {
    this.showRecoveryList = this.result.showRecoveryList;
    console.log( this.showRecoveryList);
    this.$store.state.closeDialog = true;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "closeDialog") {
        this.openDialog = state.dialogClose;
      } else if (mutation.type === "showRecoveryList") {
        this.showRecoveryList = state.showRecoveryList;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },

  /* data:function(){
      console.log(this.result);
       /!*   return {
    /!*      valid: true,
            name: '',
            nameRules: [
              v => !!v || 'Name is required',
              v => (v && v.length <= 10) || 'Name must be less than 10 characters',
            ],
            email: '',
            emailRules: [
              v => !!v || 'E-mail is required',
              v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            select: null,
            items: [
              'Item 1',
              'Item 2',
              'Item 3',
              'Item 4',
            ],
            checkbox: false,
      };*!/
   },*/

  /*    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },*/
};
</script>